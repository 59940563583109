import React from "react"
import { Link, graphql } from "gatsby"
import { navigate } from '@reach/router'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { SocialIcon } from 'react-social-icons'

import Layout from "../../components/Layout"
import Seo from "../../components/seo"
import JobCardNew from "../../components/common/JobCardNew"

const CompanyPage = ({ data }) => {
  const company = data.prismicCompany
  const compLogo = getImage(company.data.logo)
  const url = company.data.website_url
  let domain = (new URL(url))
  const jobs = data.allPrismicJobPost

  return (
    <Layout>
      <Seo
        title={company.data.company_name ? company.data.company_name : "Company Name"}
      />

      <div className="max-w-7xl mx-auto px-4 mt-6 mb-24">
        <div className="">
          <h3 className="text-gray-300 text-xl md:text-2xl tracking-wider font-semibold">Company Profile</h3>
        </div>

        <div className="mt-16">
          <div className="rounded-full flex items-center justify-center flex-shrink-0" style={{ width: '80px', height: '80px' }}>
            <GatsbyImage
              alt={company.data.logo.alt ? company.data.logo.alt : ""}
              image={compLogo}
              formats={["auto", "webp", "avif"]}
              className="rounded-full mx-auto"
            />
          </div>
          <h1 className="text-gray-200 text-5xl lg:text-7xl font-black mt-6">{company.data.company_name ? company.data.company_name : "Company Name"}</h1>
          <h3 className="mt-10 text-gray-300 text-2xl lg:text-3xl">{company.data.tagline ? company.data.tagline : "Short tagline here"}</h3>
        </div>

        <div className="mt-16">
          <div className="flex flex-col md:flex-row items-center md:space-x-4 space-y-4 md:space-y-0">
            <button
              onClick={() => navigate(`#open-positions`)}
              className="w-full md:w-auto text-indigo-100 bg-indigo-600 hover:bg-indigo-700 hover:text-white px-10 py-4 rounded-md text-center text-xl lg:text-2xl font-semibold"
            >
              Open Positions
            </button>
            <Link
              to="/post-a-job"
              className="w-full lg:w-auto text-gray-400 bg-site-color-brand-1 hover:bg-site-color-brand-3 hover:text-white px-10 py-4 rounded-md text-center text-xl lg:text-2xl font-semibold"
            >
              Hiring Devs at {company.data.company_name ? company.data.company_name : "Company Name"}?
            </Link>
          </div>
        </div>

        <div className="mt-16">
          {company.data.website_url
            ?
            <div className="mb-10">
              <p className="text-gray-400 text-lg lg:text-xl">Visit our website at <a href={company.data.website_url} target="_blank" rel="noopener noreferrer" className="underline">{domain.hostname.replace('www.','')}</a>.</p>
            </div>
            :
            null
          }

          <div className="space-x-6">
            {company.data.twitter_url
              ?
              <SocialIcon network="twitter" url={company.data.twitter_url} bgColor="#4b5563" style={{ height: 60, width: 60 }} />
              :
              null
            }
            {company.data.discord_url
              ?
              <SocialIcon network="discord" url={company.data.discord_url} bgColor="#4b5563" style={{ height: 60, width: 60 }} />
              :
              null
            }
            {company.data.telegram_url
              ?
              <SocialIcon network="telegram" url={company.data.telegram_url} bgColor="#4b5563" style={{ height: 60, width: 60 }} />
              :
              null
            }
          </div>
        </div>
      </div>

      <div id="open-positions" className="max-w-7xl mx-auto px-4 my-24">
        <div className="mt-6">
          <h2 className="text-gray-300 text-xl lg:text-2xl tracking-wider font-semibold">Open Remote Crypto Developer Positions at {company.data.company_name ? company.data.company_name : "Company Name"}</h2>

          {jobs.edges.map(item => {
            const compLogo = getImage(item.node.data.company.document.data.logo)

            return (
              <>
                {item.node.data.company.document.data.company_name === company.data.company_name
                  ?
                  <div>
                    <JobCardNew
                      jobId={item.node.id}
                      jobLink={item.node.uid}
                      jobTitle={item.node.data.job_title}
                      compLogo={compLogo}
                      compName={item.node.data.company.document.data.company_name}
                      jobType={item.node.data.job_type}
                      remoteLocation={item.node.data.remote_location}
                      minSalary={item.node.data.minimum_annual_salary}
                      maxSalary={item.node.data.maximum_annual_salary}
                      jobPostDate={item.node.first_publication_date}
                    />
                  </div>
                  :
                  null
                }
              </>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default CompanyPage

export const CompanyPageTemplateQuery = graphql`
  query($id: String!) {
    prismicCompany(id: { eq: $id }) {
      id
      prismicId
      uid
      data {
        company_name
        tagline
        twitter_url
        website_url
        telegram_url
        discord_url
        logo {
          alt
          gatsbyImageData(
            width: 80
          )
        }
      }
    }
    allPrismicJobPost(
        sort: {fields: first_publication_date, order: DESC}
      ) {
        edges {
          node {
            id
            uid
            data {
              job_title
              job_type
              remote_location
              maximum_annual_salary
              minimum_annual_salary
              company {
                document {
                  ... on PrismicCompany {
                    id
                    data {
                      company_name
                      logo {
                        alt
                        gatsbyImageData(
                          width: 80
                        )
                      }
                    }
                  }
                }
              }
              keywords {
                keyword
              }
            }
            first_publication_date(formatString: "DD MMM YYYY")
          }
        }
      }
  }
`